import Component from "./Component";

export default class ObjectRotator extends Component {
    constructor(object, speed) {
        super();
        this.object = object;
        this.speed = speed;
    }

    update(delta) {
        this.object.rotateY(this.speed * delta);
    }
}
