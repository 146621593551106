export default class Screenshot {
    static instance;

    constructor(scene, camera, renderer) {
        this.scene = scene;
        this.camera = camera;
        this.renderer = renderer;
        Screenshot.instance = this;
    }

    static takeScreenshot = () => {
        Screenshot.instance.renderer.render(
            Screenshot.instance.scene,
            Screenshot.instance.camera
        );
        const dataUrl =
            Screenshot.instance.renderer.domElement.toDataURL("image/png");
        const link = document.createElement("a");
        link.setAttribute("href", dataUrl);
        link.setAttribute("target", "_blank");
        link.setAttribute(
            "download",
            `whiteboard-${new Date().toLocaleTimeString()}.png`
        );
        link.click();
    };
}
