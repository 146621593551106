import { Button, DialogActions } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import React from "react";

function DialogIncompatibleBrowser(props){

    const handleClose = () => {
        props.onClose();
    }

    return (
        <Dialog {...props}>
            <DialogTitle>For the best experience use a Chrome-based browser</DialogTitle>
            <DialogContent>
                To get the best ZoneVS experience we recommend using the Chrome browser
                Your current browser will still work, but may not support the latest features.
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close X</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DialogIncompatibleBrowser;