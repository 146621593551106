import AgoraRTC from "agora-rtc-sdk-ng";
import { makeAutoObservable } from "mobx";
import * as THREE from "three";
import { PositionalAudioHelper } from "three/examples/jsm/helpers/PositionalAudioHelper";
import { RAYCAST_EXCLUDE_LAYER } from "../core/Scene";

const appId = "b788346730fa4d65b529a644ee50b320";

export default class LiveStreamClient {

    constructor(screenElementId, options) {
        makeAutoObservable(this);
        this.options = options;
        this.screenElementId = screenElementId;
    }

    join = async (channelId) => {
        //Setup RTC
        this.rtc_client = AgoraRTC.createClient({
            mode: "live",
            codec: "vp8",
            role: "audience",
        });

        this.uid = await this.rtc_client.join(appId, channelId, null, null);

        this.handleRtcEvents();
    }

    leave = async () => {
        await this.rtc_client.leave();
    }

    setPositionalAudio = (positionalAudio) => {
        this.positionalAudio = positionalAudio;
    }

    handleRtcEvents = () => {
        this.rtc_client.on("user-published", async (remoteUser, mediaType) => {

            await this.rtc_client.subscribe(remoteUser, mediaType);

            if (mediaType === "video") {
                const video = document.getElementById(this.screenElementId);
                const track = remoteUser.videoTrack.getMediaStreamTrack();
                video.srcObject = new MediaStream([track]);
                await video.play();
            }

            if (mediaType === "audio") {
                console.log("subscribe audio success");

                if(!this.positionalAudio) {
                    console.info("Positional audio not set for live stream screen. Falling back to standard sound.");
                    remoteUser.audioTrack.play();
                }else {
                    const stream = new MediaStream([ remoteUser.audioTrack.getMediaStreamTrack() ]);
                    this.positionalAudio.setMediaStreamSource(stream);
                }
            }
        });

        this.rtc_client.on(
            "user-unpublished",
            async (remoteUser, mediaType) => {
            }
        )
    };
}