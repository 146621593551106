import { Button, DialogActions } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import React from "react";

function DialogNotAuthorized(props){

    const handleClose = () => {
        props.onClose();
    }

    return (
        <Dialog {...props}>
            <DialogTitle>You are not allowed to join this meetingr</DialogTitle>
            <DialogContent>
                The email linked to this invitation does not match the email on your account.
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close X</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DialogNotAuthorized;