import { Transition } from "react-transition-group";

const duration = 1000;
//other values are in DynamicScene.jsx

const defaultStyle = {
    backgroundColor: "black",
    transition: `opacity ${duration}ms ease-in-out`,
    width: "100vw",
    height: "100vh",
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 1,
    pointerEvents: "none",
    zIndex: 1000
}

const transitionStyles = {
    entering: { opacity: 1 },
    entered:  { opacity: 1 },
    exiting:  { opacity: 0 },
    exited:  { opacity: 0 },
};

const Fade = ({ in: inProp, onExited }) => (
    <Transition in={inProp} timeout={duration} onExited={onExited}>
        {state => (
            <div
                style={{
                    ...defaultStyle,
                    ...transitionStyles[state]
                }}
            />
        )}
    </Transition>
);

export default Fade;