import * as THREE from "three";

export default class LoadingManager {
    static instance;

    constructor(onLoad) {
        const manager = new THREE.LoadingManager();

        manager.onStart = function (url, itemsLoaded, itemsTotal) {
            console.log(
                "Started loading file: " +
                    url +
                    ".\nLoaded " +
                    itemsLoaded +
                    " of " +
                    itemsTotal +
                    " files."
            );
        };

        manager.onLoad = function () {
            console.log("Loading complete!");
            onLoad();
        };

        manager.onProgress = function (url, itemsLoaded, itemsTotal) {
            console.log(
                "Loading file: " +
                    url +
                    ".\nLoaded " +
                    itemsLoaded +
                    " of " +
                    itemsTotal +
                    " files."
            );
        };

        manager.onError = function (url) {
            console.log("There was an error loading " + url);
        };

        LoadingManager.instance = manager;
    }
}
