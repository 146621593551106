import {
    Dialog,
    Box,
    Grid,
    Link,
    DialogContent
} from "@mui/material";
// import React, { useEffect } from "react";
//import {RecordEvent} from "../libs/AnalyticsLib";
// import Link from "@material-ui/core/Link";
// import Fab from '@material-ui/core/Fab';
// import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@mui/material/IconButton';

// https://www.webfx.com/blog/web-design/examples-of-modal-windows/

export default function ModalPricing(props) {
    const { open, onClose } = props;

    // useEffect(() => {
    //     if (open) {
    //         RecordEvent({
    //             type: "about-popup-open",
    //             id
    //
    //         })
    //     } else {
    //         RecordEvent({
    //             type: "about-popup-close",
    //             id
    //         })
    //     }
    // }, [open]);

    return (
        <Dialog
            open={open}
            fullWidth //for mobile modal
            // fullScreen={fullscreenRecommended} //for mobile fullscreen
            // maxWidth="sm" //for mobile fullscreen

            // REF
            // xs, extra-small: 0px
            // sm, small: 600px
            // md, medium: 960px
            // lg, large: 1280px
            // xl, extra-large: 1920px

            sx={{
                "& .MuiDialog-paper": {
                    // backgroundColor: "red",
                    // backgroundColor: 'rgba(17,20,49,1.0)',
                    backgroundColor: 'rgb(17,20,49)',
                    // backgroundImage: "url(" + "assets/images/banners/banner.jpg" + ")",
                    // backgroundPosition: 'center',
                    // backgroundSize: 'cover',
                    // backgroundRepeat: 'no-repeat'
                }
            }}
        >

            <DialogContent
                sx={{
                    maxWidth: "100vw",
                    position: "relative"
                }}
            >

            <IconButton
                onClick={() => onClose()}
                sx={{
                    color: "white",
                    position: 'absolute',
                    right: 0,
                    top: 0,
                }}
            >
                <CloseIcon />
            </IconButton>

            <Box py={1.0}></Box>
            <img src="assets/logos/zonevs-logo.svg" alt={""} width="100%" />


            <Grid container>

                {/*<Grid item xs={3}>*/}
                {/*</Grid>*/}
                <Grid item xs={12}  >

                    <div className="custom-text-Barlow-Regular-dialogBox" align="center"  justify='left'>

                        <Box py={1.0}></Box>
                        Bring your business and customers together in the Metaverse now.

                        <Box py={0.5}></Box>
                        Contact:
                        <Link  style={{ color: '#7cc9fd' }} href="mailto:info@zonevs.io" underline='always'  target="_blank">
                            <Box py={0.5}></Box>
                            info@zonevs.io</Link>
                        <Box py={1.0}></Box>
                    </div>
                </Grid>
                {/*<Grid item xs={3}>*/}
                {/*</Grid>*/}

            </Grid>

            <img src="assets/images/blurb/pricing.jpg" alt={""} width="100%" />

        </DialogContent>

        </Dialog>
    );
}
