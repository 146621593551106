import {
    // Button,
    Dialog,
    // DialogTitle,
    // DialogActions,
    Box,
    Grid,
    // Link,
    DialogContent,
    // useTheme,
    // useMediaQuery
} from "@mui/material";
// import React, { useEffect } from "react";
//import {RecordEvent} from "../libs/AnalyticsLib";
// import Link from "@material-ui/core/Link";
// import Fab from '@material-ui/core/Fab';
// import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@mui/material/IconButton';
// import Paper from '@material-ui/core/Paper';


// https://www.webfx.com/blog/web-design/examples-of-modal-windows/

export default function ModalAbout(props) {
    const { open, onClose } = props;
    // const theme = useTheme();
    // const fullscreenRecommended = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Dialog
            open={open}
            fullWidth //for mobile modal
            // fullScreen={fullscreenRecommended} //for mobile fullscreen
            // maxWidth="sm" //for mobile fullscreen

            // REF
            // xs, extra-small: 0px
            // sm, small: 600px
            // md, medium: 960px
            // lg, large: 1280px
            // xl, extra-large: 1920px

            sx={{
                "& .MuiDialog-paper": {
                    // backgroundColor: "red",
                    // backgroundColor: 'rgba(17,20,49,1.0)',
                    backgroundColor: 'rgb(17,20,49)',
                    // backgroundImage: "url(" + "assets/images/banners/banner.jpg" + ")",
                    // backgroundPosition: 'center',
                    // backgroundSize: 'cover',
                    // backgroundRepeat: 'no-repeat'
                }
            }}
        >

            <DialogContent
                sx={{
                    maxWidth: "100vw",
                    position: "relative"
                }}
            >

                <IconButton
                    onClick={() => onClose()}
                    sx={{
                        color: "white",
                        position: 'absolute',
                        right: 0,
                        top: 0,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Box py={1.0}></Box>
                <img src="assets/logos/zonevs-logo.svg" alt={""} width="100%" />

                <Grid container>

                    {/*<Grid item xs={3}>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12}  >

                        <div className="custom-text-Barlow-Regular-dialogBox" align="left"  justify='left'>

                            <Box py={1.0}></Box>
                            ZoneVS is a part of the Metaverse.
                            <Box py={0.5}></Box>

                            It was developed to help companies engage with their customers in new ways.
                            From high-end VR experiences to virtual spaces on the Web,
                            <Box py={0.0}></Box>
                            ZoneVS creates virtual worlds where today's business takes place.
                            <Box py={1.0}></Box>

                            ZoneVS can enable virtual exhibition  & art galleries.
                            <Box py={0.0}></Box>
                            Iconic cultural spaces and cool places people love to visit.
                            <Box py={1.0}></Box>

                            ZoneVS will allow you to immerse and engage your customers in an environment that makes your brand stick.
                            <Box py={1.0}></Box>

                            ZoneVS can be used for education, training, sales, meetings, entertainment and events.
                            <Box py={1.0}></Box>
                        </div>
                    </Grid>
                    {/*<Grid item xs={3}>*/}
                    {/*</Grid>*/}

                </Grid>

                <img src="assets/images/blurb/about.jpg" alt={""} width="100%" />

            </DialogContent>

        </Dialog>
    );
}
